import { PointCloudLayer } from "deck.gl";
import { COORDINATE_SYSTEM } from "deck.gl";

export function renderLayers(props) {
  const { data } = props;

  const layer = new PointCloudLayer({
    id: "point-cloud-layer",
    data: data,
    opacity: 1,
    pointSize: 1,
  });

  return [layer];
}
